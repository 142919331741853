<template>
  <v-card class="profile-info-container pilot-informations">
    <v-toolbar class="profile-info-toolbar">
      <v-btn @click.prevent="$router.go(-1)" class="btn-back">
        <v-icon>mdi-chevron-left</v-icon>
        <span class="ml-2">Back</span>
      </v-btn>
    </v-toolbar>
    <v-card class="profile-info-card">
      <div class="font-20 fw-500 text-left">
        Airports List
      </div>

      <!-- Search Component -->
      <v-text-field
        v-model="searchAirport"
        placeholder="Airport / City / Area / ICAO / IATA"
        prepend-inner-icon="mdi-magnify"
        color="primary"
        hide-details
        class="select-input-logbook" />

      <div class="responsive-airport-table">
        <v-data-table
          :headers="airportHeaders"
          :items="airportData"
          :items-per-page="50"
          :expanded.sync="expanded"
          sort-by="city_name"
          :search="searchAirport"
          hide-default-footer
          item-key="id"
          show-expand
          fixed-header
          mobile-breakpoint="0"
          :page.sync="airportPage"
          @page-count="pageCount = $event"
        >
          <template v-slot:item.city_name="{ item }">
            {{ item.city_name || item.name || '-' }}
          </template>
          <template v-slot:item.icao_code="{ item }">
            {{ item.icao_code || '-' }}
          </template>

          <!-- Hidden Column (For searching data purposes only) -->
          <template v-slot:item.name="{}">
            {{ '' }}
          </template>
          <template v-slot:item.area_name="{}">
            {{ '' }}
          </template>
          <template v-slot:item.iata_code="{}">
            {{ '' }}
          </template>
          <!-- End of hidden column -->

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="text-left py-3">
              <v-row no-gutters>
                <v-col cols="6" class="align-self-center mb-3">
                  <div class="font-13 fw-500">
                    Refueling :
                  </div>
                  <div class="font-14" :class="item.fuel ? 'success--text' : 'error--text'">
                    {{ item.fuel ? 'Available' : 'Not Available' }}
                  </div>
                </v-col>
                <v-col cols="6" class="align-self-center text-right mb-3">
                  <div class="font-13 fw-500">
                    GPU :
                  </div>
                  <div class="font-14" :class="item.gpu ? 'success--text' : 'error--text'">
                    {{ item.gpu ? 'Available' : 'Not Available' }}
                  </div>
                </v-col>
                <v-col cols="8" class="align-self-top mb-3">
                  <div class="font-13 fw-500">
                    Airport Name :
                  </div>
                  <div class="font-14">
                    {{ item.name }}
                  </div>
                </v-col>
                <v-col cols="4" class="align-self-top text-right mb-3">
                  <div class="font-13 fw-500">
                    IATA :
                  </div>
                  <div class="font-14">
                    {{ item.iata_code || '-' }}
                  </div>
                </v-col>
                <v-col cols="6" class="align-self-center mb-3">
                  <div class="font-13 fw-500">
                    Area :
                  </div>
                  <div class="font-14">
                    {{ item.area_name || '-' }}
                  </div>
                </v-col>
                <v-col cols="6" class="align-self-center text-right mb-3">
                  <div class="font-13 fw-500">
                    Timezone :
                  </div>
                  <div class="font-14">
                    {{ `${item.tz_name || '-'}` + (item.timezone_tz ? ` (+${item.timezone_tz})` : '') }}
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="font-13 fw-500">
                    Coordinate :
                  </div>
                  <div v-if="item.coordinate || (item.pos_latitude && item.pos_longitude)" class="font-14" @click.prevent="item.pos_latitude && item.pos_longitude ? OpenURL('https://www.google.com/maps/@' + item.pos_latitude + ',' + item.pos_longitude + ',1077m/data=!3m1!1e3') : ''">
                    <span class="align-middle">{{ item.coordinate || '-' }}</span>
                    <v-icon v-if="item.pos_latitude && item.pos_longitude" small class="align-middle ml-2">mdi-airplane-marker</v-icon>
                  </div>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="airportPage"
            :length="pageCount"
          ></v-pagination>
        </div>
      </div>

    </v-card>
  </v-card>
</template>
<script>
import { getMessaging, onMessage } from 'firebase/messaging'

export default {
  name: 'Airports',
  data: (vm) => ({
    searchAirport: '',
    expanded: [],
    airportHeaders: [
      { text: 'City', align: 'start', class: 'primary--text font-14', sortable: false, value: 'city_name' },
      { text: 'ICAO', align: 'start', class: 'primary--text font-14', sortable: false, value: 'icao_code' },
      { text: '', class: 'd-none', value: 'name' },
      { text: '', class: 'd-none', value: 'area_name' },
      { text: '', class: 'd-none', value: 'iata_code' },
      { text: '', value: 'data-table-expand' }
    ],
    airportData: [],
    airportPage: 1,
    pageCount: 0
  }),
  mounted () {
    // Receive Notification from backend
    onMessage(getMessaging(), (payload) => {
      this.$toastNotif({
        message: payload.notification.body,
        type: 'info'
      })
    })
    this.loadAirport()
  },
  methods: {
    async loadAirport () {
      await this.$store.dispatch('AIRPORT_GET', '?page=1&limit=1000')

      if (this.$store.state.airport.data.data?.data.length) {
        this.airportData = this.$store.state.airport.data.data.data
      }
    },
    OpenURL (link) {
      this.$SweetAlert({
        title: 'Leaving Forpilots?',
        caption: 'You will be redirected into external web sites.',
        confirmText: 'Continue',
        onConfirm: () => { window.open(link, 'parent') }
      })
    }
  }
}
</script>
<style lang="scss">
@import '../assets/scss/global-vars';
@import '../assets/scss/fonts';

.pilot-informations{
  .select-input-logbook{
    &.v-input--is-focused{
      .v-input__slot::after{
        transform: scaleX(.966);
      }
    }
    .v-input__slot{
      border: 1px solid #F9F9F9;
      border-radius: 10px;
      padding: 0 16px;
      &::before{
        border-color: transparent !important;
      }
    }
    .v-input__prepend-inner{
      margin-top: 0;
      align-self: center;
      .v-icon{
        &::before{
          @include font-size(22px);
        }
      }
    }
    .v-text-field__slot,
    .v-select__slot{
      input{
        @include font-size(13px);
      }
      .v-input__append-inner{
        .v-icon::before{
          color: $danger;
          @include font-size(22px);
        }
        &:last-of-type{
          display: none;
        }
      }
    }
  }
  .responsive-airport-table{
    margin-top: 20px;
    .v-data-table{
      background-color: #ededed;
      border-radius: 10px;
      thead > tr {
        th{
          background-color: #ededed;
        }
        >th:first-child{
          border-radius: 10px 0 0 0;
        }
        >th:last-child{
          border-radius: 0 10px 0 0;
        }
      }
      tbody > tr {
        >td:nth-child(3),
        >td:nth-child(4),
        >td:nth-child(5){
          display: none;
        }
      }
      .v-data-table__wrapper{
        height: calc(100vh - 400px);
      }
    }
    .v-pagination__item{
      background-color: $white;
      border-radius: 10px;
      &:not(.v-pagination__item--active){
        box-shadow: none;
      }
    }
    .v-pagination__navigation{
      border-radius: 40px;
    }
  }
}
</style>
